import * as React from "react"

import Layout from '../components/Layout'
import CenteredSection from '../components/CenteredSection'

const NotFoundPage = () => (<Layout>
  <CenteredSection>
    <h1>The page you are looking for doesn't exist</h1>
    <p>There isn't a page here at the specified URL.  You can use the menu links above to navigate and find what you are looking for.  If you think you are receiving this message in error, feel free to shoot us an email at support@oshcut.com.  Thanks!</p>
  </CenteredSection>
</Layout>)

export default NotFoundPage
